import React, { memo, useEffect } from "react";

import useReCaptcha from "../../hooks/useReCaptcha";
import Component from "./Component";

interface Props {
  formId?: string | null;
  reCaptchaKey?: string | null;
  formUrl?: string | null;
}

const ContactForm: React.FC<Props> = ({ formId, reCaptchaKey, formUrl }) => {
  const { setReCaptchaKey } = useReCaptcha();

  useEffect(() => {
    if (reCaptchaKey) {
      setReCaptchaKey(reCaptchaKey);
    }

    return () => {
      setReCaptchaKey(null);
    };
  }, [reCaptchaKey]);

  if (!formId) {
    return null;
  }

  return <Component formId={formId} formUrl={formUrl} />;
};

export default memo(ContactForm);
