import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  emailField: {
    id: "contact-form.field.email-label",
    defaultMessage: "Email",
  },
  nameField: {
    id: "contact-form.field.name-label",
    defaultMessage: "Name",
  },
  locationField: {
    id: "contact-form.field.location-label",
    defaultMessage: "Location",
  },
  messageField: {
    id: "contact-form.field.message-label",
    defaultMessage: "Message",
  },
  faxCheckbox: {
    id: "contact-form.checkbox.fax-label",
    defaultMessage: "Contact by fax",
  },
  sendButton: {
    id: "contact-form.send-button-label",
    defaultMessage: "Send message",
  },
  success: {
    id: "contact-form.success-message",
    defaultMessage: "Your message has been sent, thank you!",
  },
  error: {
    id: "contact-form.error-message",
    defaultMessage: "An error has occured, please retry in a few moments.",
  },
});

export const errorMessages = defineMessages({
  required: {
    id: "contact-form.error.field-required-label",
    defaultMessage: "This field is required",
  },
  email: {
    id: "contact-form.error.invalid-email-label",
    defaultMessage: "This field must be a valid email",
  },
});

export default messages;
