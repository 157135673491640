import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  emailField: {
    id: "newsletter-subscription-form.field.email-label",
    defaultMessage: "Email",
  },
  listField: {
    id: "newsletter-subscription-form.field.list-label",
    defaultMessage: "List",
  },
  sendButton: {
    id: "newsletter-subscription-form.send-button-label",
    defaultMessage: "Subscribe",
  },
  success: {
    id: "newsletter-subscription-form.success-message",
    defaultMessage: "Your subscription has been taken into account, thank you!",
  },
  error: {
    id: "newsletter-subscription-form.error-message",
    defaultMessage: "An error has occured, please retry in a few moments.",
  },
});

export const errorMessages = defineMessages({
  required: {
    id: "newsletter-subscription-form.error.field-required-label",
    defaultMessage: "This field is required",
  },
  email: {
    id: "newsletter-subscription-form.error.invalid-email-label",
    defaultMessage: "This field must be a valid email",
  },
});

export default messages;
