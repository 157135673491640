/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import { TheaterSelector } from "gatsby-theme-theater-selector";
import React, { memo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useIntl from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  Input,
  jsx,
  Label,
  Spinner,
  Textarea,
} from "theme-ui";

import ErrorMessage from "./ErrorMessage";
import messages from "./i18n";

type FormShape = {
  email: string;
  name: string;
  contact_by_fax: boolean | undefined;
  message: string;
};

interface Props {
  formId: string;
  formUrl?: string | null;
}

const ContactFormComponent: React.FC<Props> = ({ formId, formUrl }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isSingleLocation } = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const {
    values,
    setValue,
    setFieldTouched,
    resetValues,
    errors,
    touched,
    isValid,
  } = useForm<FormShape>({
    initialValues: {
      email: "",
      name: "",
      contact_by_fax: undefined,
      message: "",
    },
    validationSchema: yup.object({
      email: yup.string().email("email").required("required"),
      name: yup.string().required("required"),
      contact_by_fax: yup.boolean(),
      message: yup.string().required("required"),
    }),
  });

  const onSubmit = async () => {
    if (!formUrl) {
      return;
    }

    setLoading(true);
    setSuccess(false);
    setError(false);

    const token = await executeRecaptcha?.("CONTACT_FORM");

    const theaterId = !isSingleLocation
      ? btoa(`Theater:${selectedTheaterId}`)
      : undefined;

    const payload = JSON.stringify({
      ...values,
      id: formId,
      theaterId,
      "g-recaptcha-response": token,
    });

    fetch(formUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then((response) => {
        setLoading(false);

        if (response.status === 200) {
          setSuccess(true);
          setError(false);
          resetValues();
        } else {
          setSuccess(false);
          setError(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
        setError(true);
      });
  };

  return (
    <div>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          await onSubmit();
        }}
      >
        <Grid>
          {!isSingleLocation && (
            <div>
              <Label>{formatMessage(messages.locationField)}</Label>
              <TheaterSelector />
            </div>
          )}

          <Grid columns={responsive({ xs: "1fr", sm: "1fr 1fr" })}>
            <div>
              <Label htmlFor={`email-${formId}`}>
                {`${formatMessage(messages.emailField)} *`}
              </Label>
              <Input
                id={`email-${formId}`}
                name={"email"}
                type={"email"}
                value={values.email}
                onChange={(event) => {
                  setValue("email", event.target.value);
                  setSuccess(false);
                  setError(false);
                }}
                onBlur={() => {
                  setFieldTouched("email");
                }}
              />
              {touched.email && errors.email && (
                <ErrorMessage type={errors.email} />
              )}
            </div>

            <div>
              <Label htmlFor={`name-${formId}`}>
                {`${formatMessage(messages.nameField)} *`}
              </Label>
              <Input
                id={`name-${formId}`}
                name={"name"}
                value={values.name}
                onChange={(event) => {
                  setValue("name", event.target.value);
                  setSuccess(false);
                  setError(false);
                }}
                onBlur={() => {
                  setFieldTouched("name");
                }}
              />
              {touched.name && errors.name && (
                <ErrorMessage type={errors.name} />
              )}
            </div>
          </Grid>

          <div>
            <Label htmlFor={`message-${formId}`}>
              {`${formatMessage(messages.messageField)} *`}
            </Label>
            <Textarea
              id={`message-${formId}`}
              name="message"
              rows={10}
              value={values.message}
              onChange={(event) => {
                setValue("message", event.target.value);
                setSuccess(false);
                setError(false);
              }}
              onBlur={() => {
                setFieldTouched("message");
              }}
              sx={{
                resize: "vertical",
              }}
            />
            {touched.message && errors.message && (
              <ErrorMessage type={errors.message} />
            )}
          </div>

          <div sx={{ position: "absolute", left: "-100vw" }}>
            <Label htmlFor={`contact_by_fax-${formId}`}>
              {`${formatMessage(messages.faxCheckbox)}`}
            </Label>
            <Checkbox
              id={`contact_by_fax-${formId}`}
              name="contact_by_fax"
              type="checkbox"
              checked={values.contact_by_fax}
              onChange={(event) => {
                setValue("contact_by_fax", event.target.checked);
              }}
            />
          </div>

          <div>
            <Flex sx={{ alignItems: "center" }}>
              <div>
                <Button
                  variant={"buttons.secondary"}
                  disabled={!isValid || loading}
                  type={"submit"}
                >
                  {formatMessage(messages.sendButton)}
                </Button>
              </div>
              <div sx={{ marginLeft: 3 }}>
                {loading && (
                  <div>
                    <Spinner size={"2rem"} />
                  </div>
                )}
                {success && (
                  <div sx={{ fontWeight: "bold" }}>
                    {formatMessage(messages.success)}
                  </div>
                )}
                {error && (
                  <div sx={{ fontWeight: "bold" }}>
                    {formatMessage(messages.error)}
                  </div>
                )}
              </div>
            </Flex>
          </div>
        </Grid>
      </form>
    </div>
  );
};

export default memo(ContactFormComponent);
