/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import React, { memo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useIntl from "shared/helpers/i18n/useIntl";
import useForm, { yup } from "shared/hooks/useForm";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import {
  Button,
  Checkbox,
  Grid,
  Input,
  jsx,
  Label,
  Select,
  Spinner,
} from "theme-ui";

import ErrorMessage from "./ErrorMessage";
import messages from "./i18n";

type Action = {
  type: "add" | "remove";
  id: string;
};

type FormShape = {
  email: string;
  actions: Action[];
  contact_by_fax: boolean | undefined;
};

export type List = {
  id?: string | null;
  name?: string | null;
};

interface Props {
  formId: string;
  formUrl?: string | null;
  lists?: ReadonlyArray<List | null> | null;
}

const NewsletterSubscriptionFormComponent: React.FC<Props> = ({
  formId,
  formUrl,
  lists,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isSingleLocation } = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const initialActions: Action[] = [];

  const hasMultipleChoices = (lists?.length || 0) > 1;

  if (lists?.length === 1) {
    const listId = lists[0]?.id;
    if (listId) {
      initialActions.push({ type: "add", id: listId });
    }
  }

  const {
    values,
    setValue,
    setFieldTouched,
    resetValues,
    errors,
    touched,
    isValid,
  } = useForm<FormShape>({
    initialValues: {
      email: "",
      actions: initialActions,
      contact_by_fax: undefined,
    },
    validationSchema: yup.object({
      email: yup.string().email("email").required("required"),
      actions: yup
        .array()
        .of(
          yup.object({
            type: yup
              .mixed<Action["type"]>()
              .oneOf(["add", "remove"])
              .required(),
            id: yup.string().required(),
          })
        )
        .min(1)
        .required(),
      contact_by_fax: yup.boolean(),
    }),
  });

  const onSubmit = async () => {
    if (!formUrl) {
      return;
    }

    setLoading(true);
    setSuccess(false);
    setError(false);

    const token = await executeRecaptcha?.("CONTACT_FORM");

    const theaterId = !isSingleLocation
      ? btoa(`Theater:${selectedTheaterId}`)
      : undefined;

    const payload = JSON.stringify({
      ...values,
      id: formId,
      theaterId,
      "g-recaptcha-response": token,
    });

    fetch(formUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: payload,
    })
      .then((response) => {
        setLoading(false);

        if (response.status === 200) {
          setSuccess(true);
          setError(false);
          resetValues();
        } else {
          setSuccess(false);
          setError(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setSuccess(false);
        setError(true);
      });
  };

  return (
    <div>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          await onSubmit();
        }}
      >
        <div sx={{ position: "absolute", left: "-100vw" }}>
          <Checkbox
            id={`contact_by_fax-${formId}`}
            name="contact_by_fax"
            type="checkbox"
            checked={values.contact_by_fax}
            onChange={(event) => {
              setValue("contact_by_fax", event.target.checked);
            }}
          />
        </div>

        {hasMultipleChoices ? (
          <Grid gap={3}>
            <div sx={{ display: responsive({ lg: "flex" }) }}>
              <div sx={{ flex: 1 }}>
                <Grid
                  columns={responsive({ xs: "1fr", lg: "1fr 1fr" })}
                  gap={3}
                >
                  {/* mail */}
                  <div>
                    <Label htmlFor={`email-${formId}`}>
                      {`${formatMessage(messages.emailField)} *`}
                    </Label>
                    <Input
                      id={`email-${formId}`}
                      name={"email"}
                      type={"email"}
                      value={values.email}
                      onChange={(event) => {
                        setValue("email", event.target.value);
                        setSuccess(false);
                        setError(false);
                      }}
                      onBlur={() => {
                        setFieldTouched("email");
                      }}
                    />
                    {touched.email && errors.email && (
                      <ErrorMessage type={errors.email} />
                    )}
                  </div>

                  {/* list selection */}
                  <div>
                    <Label htmlFor={`list-${formId}`}>
                      {`${formatMessage(messages.listField)} *`}
                    </Label>
                    <Select
                      id={`list-${formId}`}
                      sx={{
                        backgroundColor: "white",
                        borderWidth: "2px",
                        borderColor: "primary",
                        color: "#000",
                        "& + svg": {
                          fill: "black",
                        },
                      }}
                      defaultValue={""}
                      onChange={(e) => {
                        setValue("actions", [
                          {
                            id: e.target.value,
                            type: "add",
                          },
                        ]);
                      }}
                    >
                      <option disabled value={""} />
                      {lists?.map((list) => {
                        if (!list?.id) {
                          return null;
                        }
                        return (
                          <option value={list.id} key={list.id}>
                            {list.name}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                </Grid>
              </div>

              {/* submit when multiple choices */}
              <div
                sx={{
                  textAlign: "center",
                  marginLeft: responsive({ lg: 3 }),
                  marginTop: responsive({ xs: 3, lg: 0 }),
                  flex: 0,
                }}
              >
                <Label
                  sx={{ display: responsive({ xs: "none", lg: "block" }) }}
                >
                  <wbr />
                </Label>
                <Button
                  variant={"buttons.secondary"}
                  disabled={!isValid || loading}
                  type={"submit"}
                  sx={{ width: "100%" }}
                >
                  {formatMessage(messages.sendButton)}
                </Button>
              </div>
            </div>

            {/* messages */}
            {(loading || success || error) && (
              <div sx={{ textAlign: "center" }}>
                {loading && (
                  <div
                    sx={{
                      lineHeight: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner size={"2rem"} sx={{ display: "block" }} />
                  </div>
                )}
                {success && (
                  <div sx={{ fontWeight: "bold", textAlign: "center" }}>
                    {formatMessage(messages.success)}
                  </div>
                )}
                {error && (
                  <div sx={{ fontWeight: "bold", textAlign: "center" }}>
                    {formatMessage(messages.error)}
                  </div>
                )}
              </div>
            )}
          </Grid>
        ) : (
          <Grid gap={3}>
            <div sx={{ display: responsive({ md: "flex" }) }}>
              <div sx={{ flex: 1 }}>
                <Label htmlFor={`email-${formId}`}>
                  {`${formatMessage(messages.emailField)} *`}
                </Label>
                <Input
                  id={`email-${formId}`}
                  name={"email"}
                  type={"email"}
                  value={values.email}
                  onChange={(event) => {
                    setValue("email", event.target.value);
                    setSuccess(false);
                    setError(false);
                  }}
                  onBlur={() => {
                    setFieldTouched("email");
                  }}
                />
                {touched.email && errors.email && (
                  <ErrorMessage type={errors.email} />
                )}
              </div>

              {/* submit when single selection */}
              <div
                sx={{
                  textAlign: "center",
                  marginLeft: responsive({ md: 3 }),
                  marginTop: responsive({ xs: 3, md: 0 }),
                  flex: 0,
                }}
              >
                <Label
                  sx={{ display: responsive({ xs: "none", md: "block" }) }}
                >
                  <wbr />
                </Label>
                <div>
                  <Button
                    variant={"buttons.secondary"}
                    disabled={!isValid || loading}
                    type={"submit"}
                    sx={{ width: "100%" }}
                  >
                    {formatMessage(messages.sendButton)}
                  </Button>
                </div>
              </div>
            </div>

            {/* messages */}
            {(loading || success || error) && (
              <div sx={{ textAlign: "center" }}>
                {loading && (
                  <div
                    sx={{
                      lineHeight: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner size={"2rem"} sx={{ display: "block" }} />
                  </div>
                )}
                {success && (
                  <div sx={{ fontWeight: "bold", textAlign: "center" }}>
                    {" "}
                    {formatMessage(messages.success)}
                  </div>
                )}
                {error && (
                  <div sx={{ fontWeight: "bold", textAlign: "center" }}>
                    {" "}
                    {formatMessage(messages.error)}
                  </div>
                )}
              </div>
            )}
          </Grid>
        )}
      </form>
    </div>
  );
};

export default memo(NewsletterSubscriptionFormComponent);
