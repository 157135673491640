import React, { memo, useEffect } from "react";

import useReCaptcha from "../../hooks/useReCaptcha";
import Component, { List } from "./Component";

interface Props {
  formId?: string | null;
  reCaptchaKey?: string | null;
  formUrl?: string | null;
  lists?: ReadonlyArray<List | null> | null;
}

const NewsletterSubscriptionForm: React.FC<Props> = ({
  formId,
  reCaptchaKey,
  formUrl,
  lists,
}) => {
  const { setReCaptchaKey } = useReCaptcha();

  useEffect(() => {
    if (reCaptchaKey) {
      setReCaptchaKey(reCaptchaKey);
    }

    return () => {
      setReCaptchaKey(null);
    };
  }, [reCaptchaKey]);

  if (!formId) {
    return null;
  }

  return <Component formId={formId} formUrl={formUrl} lists={lists} />;
};

export default memo(NewsletterSubscriptionForm);
